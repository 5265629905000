@import "normalize.css";

:root {
  --color-reset: #41aaa3;
  --color-bg: #070707;
}

html,
body {
  background-color: var(--color-bg);

  display: flex;

  align-items: center;
  justify-content: center;

  height: 100%;
}

body {
  will-change: opacity;
}

.noise {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-image: url("../noise.png");
  // mix-blend-mode: soft-light;

  will-change: opacity;
  opacity: 0.05;

  background-size: 100px;

  z-index: 100;

  pointer-events: none;
}

.start-btn {
  position: absolute;

  width: 64px;
  height: 64px;

  outline: 0;
  border: 0;

  top: 50%;
  left: 50%;

  filter: blur(0.5px);

  z-index: 10;

  transform: translateX(-50%) translateY(-50%);
  background-color: var(--color-bg);

  padding: 10px;

  color: var(--color-reset);

  box-shadow: 0 0 10px rgba(0, 0, 0, 1), 0 0 4px rgba(0, 0, 0, 1);
  border: 2px solid black;

  cursor: pointer;

  .ios & {
    filter: none;
  }

  .transition & {
    transition: {
      property: opacity;
      duration: 2s;
    }
  }

  .start & {
    opacity: 0;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 8px rgba(0, 0, 0, 1), 0 0 3px rgba(0, 0, 0, 1);
  }

  &:active {
    box-shadow: 0 0 3px rgba(0, 0, 0, 1), 0 0 1px rgba(0, 0, 0, 1);
  }

  svg {
    width: 100%;
  }

  .light {
    position: absolute;

    top: 6px;
    right: 6px;

    width: 5px;
    height: 5px;

    border-radius: 100%;

    color: rgb(196, 28, 28);
    background-color: currentColor;

    &.on {
      color: rgb(64, 172, 37);
    }

    &:before {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      background: radial-gradient(currentColor 10%, transparent 65%);

      opacity: 0.25;
    }

    &:after {
      content: "";
      position: absolute;

      z-index: 2;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      border-radius: 100%;
      background-color: currentColor;
    }
  }
}

.video-container {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 20;

  background-color: black;

  opacity: 0;
  visibility: hidden;

  .transition & {
    transition: {
      duration: 0.5s;
      property: opacity, visibility;
    }
  }

  .start & {
    opacity: 1;
    visibility: visible;
  }
}

.video-player {
  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate3d(-50%, -50%, 0);

  width: 100%;
  height: 0;

  padding-bottom: 56.25%;

  opacity: 0;

  .transition & {
    transition: {
      property: opacity;
      duration: 0.1s;
    }
  }

  &.show {
    opacity: 1;
  }

  video {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
}

.video-success {
  width: 100%;
  height: 100%;

  left: 0;

  font-size: 5vw;

  @media screen and (min-width: 500px) {
    font-size: 2.5vw;
  }
}

.video-fail {
  width: 70%;
  height: 100%;

  right: 0;

  font-size: 4.5vw;

  @media screen and (min-width: 500px) {
    font-size: 3.5vw;
  }
}

.options {
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;

  font-family: "Varela Round", sans-serif;

  z-index: 100;

  text-align: center;

  filter: blur(1px);

  opacity: 0;
  visibility: hidden;

  .transition & {
    transition: {
      property: visibility, opacity;
      duration: 0.15s;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  span,
  a {
    display: block;

    color: #3e3e3e;

    margin: 0.2vw 0;

    text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
      1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
    text-decoration: none;

    &.active {
      color: #55b6cc;
    }
  }
}

.volume {
  color: #3e3e3e;

  position: absolute;

  top: 10px;
  right: 10px;

  z-index: 500;

  width: 64px;

  background-color: transparent;
  border: 0;

  padding: 0;
  margin: 0;

  filter: blur(1px);

  display: none;

  .muted.start & {
    display: block;
  }
}
